@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}

  .transitions {
    animation: fadeIn 1s cubic-bezier(0.76, 0, 0.24, 1);

   }
  
  .extendedTransition {
    animation: fadeIn 1s ease-in;

  }

  .rad {
  -webkit-appearance: none;
  -webkit-background-composite: border;
  }
