* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}



html {
    height: 100%;
  
}
body {
    
    min-height: 100%;
   
}
