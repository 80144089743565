.date-picker {
    padding: 10px;
    border-radius: 15px;
    border: 2px solid #D9D9D9;
    cursor: pointer;
font-family: 'Manrope';
width: 100%;
font-weight:700;
color: #363D49;
    
}


.text-fields {
    height: 97px;
    min-height: 97px;
    width: 100%;
    background-color: #FFF;
    border: 2px solid #FFF;
    border-radius: 9px;
    text-align: center;
    font-family: Manrope;
    color: #363D49;
    font-size: 22px;
    font-weight: 700
}

.text-fields::placeholder {
    text-align: center;
    font-family: Manrope;
    color: #AEAEAE;
    font-size: 22px;
    font-weight: 700;
}

.text-fields:focus {
outline: none;   
}