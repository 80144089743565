@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

body {
    font-family: 'Manrope', sans-serif !important;

    background-color: #4E505C;
    object-fit: cover;
    background-repeat: unset no-repeat;

}

@media (min-width: 700px) {
    body {
        background-repeat: inherit no-repeat;
        mask-repeat: no-repeat;
        object-fit: cover;

    }

}

@media (min-width: 1300px) {
    body {
        background-repeat: round no-repeat;

    }
}

.css-1x2jons-MuiFormControlLabel-asterisk {
    display: none !important;
}